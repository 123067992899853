import { createSlice } from "@reduxjs/toolkit";
import {
  actGetAppartements,
  actGetAppartement,
  actCreateAppartement,
  actUpdateAppartement,
  actDeleteAppartement,
  actGetPropertyCounts,
  actGetPopularProperties,
  actfindNearbyApartments,
} from "./AppartActions";

const initialState = {
  records: [],
  record: {},
  propertyCounts: {},
  popularProperties: [],
  nearbyProperties: [],
  loading: false,
  error: null,
};

const appartSlice = createSlice({
  name: "appartements",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Récupérer les décomptes des propriétés
    builder.addCase(actGetPropertyCounts.fulfilled, (state, action) => {
      state.propertyCounts = action.payload;
    });
    builder.addCase(actGetPropertyCounts.rejected, (state, action) => {
      state.error = action.payload;
    });

    builder.addCase(actGetPopularProperties.fulfilled, (state, action) => {
      state.popularProperties = action.payload;
    });

    // Récupérer tous les appartements
    builder.addCase(actGetAppartements.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(actGetAppartements.fulfilled, (state, action) => {
      state.loading = false;
      state.records = action.payload;
    });
    builder.addCase(actGetAppartements.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Récupérer un appartement par ID
    builder.addCase(actGetAppartement.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(actGetAppartement.fulfilled, (state, action) => {
      state.loading = false;
      state.record = action.payload;
    });
    builder.addCase(actGetAppartement.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Créer un nouvel appartement
    builder.addCase(actCreateAppartement.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(actCreateAppartement.fulfilled, (state, action) => {
      state.loading = false;
      state.records.push(action.payload);
    });
    builder.addCase(actCreateAppartement.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });


    // Mettre à jour un appartement
    builder.addCase(actUpdateAppartement.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(actUpdateAppartement.fulfilled, (state, action) => {
      state.loading = false;
      state.records = state.records.map((appart) => {
        if (appart._id === action.payload._id) {
          return action.payload;
        }
        return appart;
      });
    });
    builder.addCase(actUpdateAppartement.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Supprimer un appartement
    builder.addCase(actDeleteAppartement.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(actDeleteAppartement.fulfilled, (state, action) => {
      state.loading = false;
      state.records = state.records.filter((appart) => appart._id !== action.payload._id);
    });
    builder.addCase(actDeleteAppartement.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Trouver des appartements à proximité
    builder.addCase(actfindNearbyApartments.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(actfindNearbyApartments.fulfilled, (state, action) => {
      state.loading = false;
      state.records = action.payload;
    });
    builder.addCase(actfindNearbyApartments.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default appartSlice.reducer;
export { actGetAppartements, actGetAppartement, actCreateAppartement, actUpdateAppartement, actDeleteAppartement, actGetPropertyCounts, actGetPopularProperties, actfindNearbyApartments };
