// CustomAlertTemplate.jsx
import React from 'react';

function CustomAlertTemplate({ style, options, message, close }) {
  const alertStyles = {
    success: {
      backgroundColor: '#d4edda',
      color: 'green',
      padding: '20px',
      borderRadius: '8px'
    },
    error: {
      backgroundColor: '#f8d7da',
      color: 'red',
      padding: '20px',
      borderRadius: '8px'
    },
    info: {
      backgroundColor: '#d1ecf1',
      color: 'blue',
      padding: '20px',
      borderRadius: '8px'
    }
  };

  const currentStyle = options.type ? alertStyles[options.type] : {};

  return (
    <div style={{ ...style, ...currentStyle }}>
      {message} ‎ ‎
      <button onClick={close}> Close</button>
    </div>
  );
}

export default CustomAlertTemplate;