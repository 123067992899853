import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Map extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'

        return <div className="google-map mb-120">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3711.27362831412!2d2.340063845237887!3d48.872191201987974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e3ea10f4365%3A0x7ae02982868978b0!2s15%20Bd%20Montmartre%2C%2075002%20Paris%2C%20France!5e0!3m2!1sen!2sma!4v1725438204887!5m2!1sen!2sma"
                width="100%"
                height="100%"
                frameBorder={0}
                allowFullScreen
                aria-hidden="false"
                tabIndex={0} />
        </div>
    }
}

export default Map