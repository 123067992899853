import React from 'react';
import { useDispatch } from 'react-redux';
import { submitActualites } from '../../slices/actualite/ActualiteActions';
import { useAlert } from 'react-alert';

function AddListing() {
    const dispatch = useDispatch();
    const alert = useAlert();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        try {
            const response = await dispatch(submitActualites(formData));
            if (submitActualites.fulfilled.match(response)) {
                alert.success('Actualites submitted successfully!', { type: 'success' });
            } else {
                throw new Error('Failed to submit actualites', { type: 'error' });
            }
        } catch (error) {
            alert.error(`Error: ${error.message || 'Unknown error'}`);
        }
    };

    return (
        <div className="ltn__appointment-area pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ltn__appointment-inner">
                            <form onSubmit={handleSubmit}>
                                <h2>1. Description</h2>
                                <p><small>These fields are mandatory: Title, Media</small></p>
                                <div className="row">
                                    <h6>1. Title Principale</h6>
                                    <div className="col-md-12">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="title" placeholder="*Title (mandatory)"  />
                                        </div>
                                        <h6>2. Description Principale</h6>
                                        <div className="input-item input-item-textarea ltn__custom-icon">
                                            <textarea name="description" placeholder="* Description"  />
                                        </div>
                                        <h6>1. Title N°1</h6>
                                        <div className="input-item input-item-textarea">
                                            <input style={{ height: "50px" }} type="text" name="title1" placeholder="Title N°1" />
                                        </div>
                                        <h6>2. Description N°1</h6>
                                        <div className="input-item input-item-textarea ltn__custom-icon">
                                            <textarea name="description1" placeholder="Description N°1"  />
                                        </div>
                                        <h6>1. Title N°2</h6>
                                        <div className="input-item input-item-textarea">
                                            <input style={{ height: "50px" }} type="text" name="title2" placeholder="Title N°2" />
                                        </div>
                                        <h6>2. Description N°2</h6>
                                        <div className="input-item input-item-textarea ltn__custom-icon">
                                            <textarea name="description2" placeholder="Description N°2"  />
                                        </div>
                                        <h6>1. Title N°3</h6>
                                        <div className="input-item input-item-textarea">
                                            <input style={{ height: "50px" }} type="text" name="title3" placeholder="Title N°3" />
                                        </div>
                                        <h6>2. Description N°3</h6>
                                        <div className="input-item input-item-textarea ltn__custom-icon">
                                            <textarea name="description3" placeholder="Description N°3"  />
                                        </div>
                                        <h6>1. Title N°4</h6>
                                        <div className="input-item input-item-textarea">
                                            <input style={{ height: "50px" }} type="text" name="title4" placeholder="Title N°4" />
                                        </div>
                                        <h6>2. Description N°4</h6>
                                        <div className="input-item input-item-textarea ltn__custom-icon">
                                            <textarea name="description4" placeholder="Description N°4"  />
                                        </div>
                                        <h6>3. Author</h6>
                                        <div className="input-item input-item-textarea">
                                            <input style={{ height: "50px" }} type="text" name="author" placeholder="Author" />
                                        </div>
                                        <h6 style={{ marginTop: "-15px" }}>4. Date</h6>
                                        <div className="input-item input-item-name" style={{ marginTop: "10px", marginBottom: '32px' }}>
                                            <input type="date" name="date"  />
                                        </div>
                                    </div>
                                </div>
                                <h2>2. Media</h2>
                                <h6>Listing Media</h6>
                                <input type="file" id="myFile" name="image" className="btn theme-btn-3 mb-10"  /><br />
                                <p>
                                    <small>* At least 1 image is required for a valid submission. Minimum size is 500/500px.</small><br />
                                    <small>* Images might take longer to be processed.</small>
                                </p>

                                <div className="alert alert-warning d-none" role="alert">
                                    Please note that the date and time you requested may not be available. We will contact you to confirm your actual appointment details.
                                </div>
                                <div className="btn-wrapper text-center mt-30">
                                    <button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">Submit Actualites</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddListing;