import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';
import { useDispatch } from 'react-redux';
import { actCreateAppartement } from '../../slices/appartement/AppartActions';
import Select from 'react-select';
import MapSelect from './MapSelect';
import axios from 'axios';
const BASE_URL = "/api"

const AddListing = () => {
    const dispatch = useDispatch();
    const [longitude, setLongitude] = useState('');
    const [latitude, setLatitude] = useState('');
    const [metros, setMetros] = useState([]);
    const [tramways, setTramways] = useState([]);
    const [rers, setRers] = useState([]);

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        price: '',
        priceLabel: '',
        beforePriceLabel: '',
        taxRate: '',
        hoaFee: '',
        category: '',
        type: '',
        status: '',
        adress: '',
        ville: '',
        pays: '',
        quartier: '',
        codePostal: '',
        longitude: '',
        latitude: '',
        surface: '',
        lotSize: '',
        rooms: '',
        bedrooms: '',
        bathrooms: '',
        customId: '',
        garages: '',
        yearBuilt: '',
        garageSize: '',
        availableFrom: '',
        basement: '',
        extraDetails: '',
        roofing: '',
        exteriorMaterial: '',
        structure: '',
        floorsNo: '',
        notes: '',
        energyClass: '',
        energyIndex: '',
        interiorEquipments: [],
        exteriorEquipments: [],
        caracteristics: [],
        equipments: [],
        image: [],
        gallery: [],
        video: '',
        virtualTour: '',
        metro: [],
        tramway: [],
        rer: [],
    });

    console.log(rers);

    useEffect(() => {
        async function getMetros() {
            try {
                const response = await axios.get(`${BASE_URL}/metros`);
                setMetros(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        async function getTramways() {
            try {
                const response = await axios.get(`${BASE_URL}/trams`);
                setTramways(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        async function getRers() {
            try {
                const response = await axios.get(`${BASE_URL}/rer`);
                setRers(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        getMetros();
        getTramways();
        getRers();



        return () => {
            setMetros([]);
            setTramways([]);
            setRers([]);
        };
    }, []);

    const category = [
        { value: 'Appartement', label: 'Appartement' },
        { value: 'Maison', label: 'Maison' }
    ];

    const type = [
        // { value: 'Location', label: 'Location' },
        { value: 'Vente', label: 'Vente' }
    ];

    const status = [
        { value: 'Actif', label: 'Actif' },
        { value: 'Offre chaude', label: 'Offre chaude' },
        { value: 'Nouvelle offre', label: 'Nouvelle offre' },
        { value: 'Portes ouvertes', label: 'Portes ouvertes' },
        { value: 'Vendu', label: 'Vendu' }
    ];

    const structure = [
        { value: 'Non disponible', label: 'Non disponible' },
        { value: 'Brique', label: 'Brique' },
        { value: 'Bois', label: 'Bois' },
        { value: 'Ciment', label: 'Ciment' }
    ];

    const floorsNo = [
        { value: 'Non disponible', label: 'Non disponible' },
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' }
    ];

    const energyClass = [
        { value: 'A+', label: 'A+' },
        { value: 'A', label: 'A' },
        { value: 'B', label: 'B' },
        { value: 'C', label: 'C' },
        { value: 'D', label: 'D' },
        { value: 'E', label: 'E' }
    ];

    const department = [
        { value: 'Paris', label: 'Paris (75)' },
        { value: 'Seine-et-Marne', label: 'Seine-et-Marne (77)' },
        { value: 'Yvelines', label: 'Yvelines (78)' },
        { value: 'Essonne', label: 'Essonne (91)' },
        { value: 'Hauts-de-Seine', label: 'Hauts-de-Seine (92)' },
        { value: 'Seine-Saint-Denis', label: 'Seine-Saint-Denis (93)' },
        { value: 'Val-de-Marne', label: 'Val-de-Marne (94)' },
        { value: 'Val-d-Oise', label: 'Val d-Oise (95)' },
    ];

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;

        if (type === 'file') {
            if (name === 'image') {
                setFormData(prevState => ({
                    ...prevState,
                    image: Array.from(files)
                }));
            } else if (name === 'gallery') {
                setFormData(prevState => ({
                    ...prevState,
                    gallery: Array.from(files)
                }));
            }
        } else if (type === 'checkbox') {
            setFormData(prevState => {
                const arrayToUpdate = prevState[name];
                if (checked) {
                    return {
                        ...prevState,
                        [name]: [...arrayToUpdate, value]
                    };
                } else {
                    return {
                        ...prevState,
                        [name]: arrayToUpdate.filter(item => item !== value)
                    };
                }
            });
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
        console.log(formData);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData();

        // Append all form data to FormData
        Object.keys(formData).forEach(key => {
            if (key === 'image' || key === 'gallery') {
                formData[key].forEach(file => {
                    data.append(key, file);
                });
            } else if (Array.isArray(formData[key])) {
                formData[key].forEach(value => {
                    data.append(key, value);
                });
            } else {
                data.append(key, formData[key]);
            }
        });

        dispatch(actCreateAppartement(data));
    };

    const handleMapChange = (long, lat) => {
        setFormData(prevState => ({
            ...prevState,
            longitude: long.toString(),
            latitude: lat.toString()
        }));
    };

    // let publicUrl = process.env.PUBLIC_URL + '/';

    return (
        <div className="ltn__appointment-area pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ltn__appointment-inner">
                            <form onSubmit={handleSubmit}>
                                <h2>1. Description</h2>
                                <p><small>Ces champs sont obligatoires : Titre, Médias de la propriété</small></p>
                                <h6>Description de la propriété</h6>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="title" value={formData.title} onChange={handleChange} placeholder="*Titre (obligatoire)" />
                                        </div>
                                        <div className="input-item input-item-textarea ltn__custom-icon">
                                            <textarea name="description" value={formData.description} onChange={handleChange} placeholder="Description" />
                                        </div>
                                    </div>
                                </div>
                                <h6>Prix de la propriété</h6>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="price" value={formData.price} onChange={handleChange} placeholder="Prix en € (seulement des chiffres)" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="priceLabel" value={formData.priceLabel} onChange={handleChange} placeholder="Libellé après le prix (ex: /mois)" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="beforePriceLabel" value={formData.beforePriceLabel} onChange={handleChange} placeholder="Libellé avant le prix (ex: à partir de)" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="taxRate" value={formData.taxRate} onChange={handleChange} placeholder="Taux d'imposition annuel" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="hoaFee" value={formData.hoaFee} onChange={handleChange} placeholder="Frais de copropriété (mensuel)" />
                                        </div>
                                    </div>
                                </div>
                                <h6>Select Categories</h6>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="input-item">
                                            <Select
                                                options={category}
                                                value={category.find(option => option.value === formData.type)}
                                                placeholder="Selectionnez la category"
                                                onChange={(selectedOption) => {
                                                    console.log(selectedOption);

                                                    setFormData(prevState => ({
                                                        ...prevState,
                                                        category: selectedOption.value
                                                    }));
                                                }}
                                            />
                                            {/* <select id='category' className='category' value={formData.category} onChange={handleChange}> */}
                                            {/* <option value="aucun">Aucun</option> */}
                                            {/* <option value="appartements">Appartements</option> */}
                                            {/* <option value="condos">Condos</option> */}
                                            {/* <option value="duplex">Duplex</option> */}
                                            {/* <option value="maisons">Maisons</option> */}
                                            {/* <option value="industriel">Industriel</option> */}
                                            {/* <option value="terrain">Terrain</option> */}
                                            {/* <option value="bureaux">Bureaux</option> */}
                                            {/* <option value="commerce">Commerce</option> */}
                                            {/* <option value="villas">Villas</option> */}
                                            {/* </select> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="input-item">
                                            <Select
                                                options={type}
                                                value={type.find(option => option.value === formData.category)}
                                                placeholder="Selectionnez le type"
                                                onChange={(selectedOption) => {
                                                    console.log(selectedOption);

                                                    setFormData(prevState => ({
                                                        ...prevState,
                                                        type: selectedOption.value
                                                    }));
                                                }}
                                            />
                                            {/* <select id='type' className="nice-select" value={formData.type} onChange={handleChange} >
                                                <option value="aucun">Aucun</option>
                                                <option value="locations">Locations</option>
                                                <option value="ventes">Ventes</option>
                                            </select> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="input-item">
                                            <Select
                                                options={status}
                                                value={status.find(option => option.value === formData.status)}
                                                placeholder="Selectionnez le Status"
                                                onChange={(selectedOption) => {
                                                    console.log(selectedOption);

                                                    setFormData(prevState => ({
                                                        ...prevState,
                                                        status: selectedOption.value
                                                    }));
                                                }}
                                            />
                                            {/* <select id='status' className="nice-select" value={formData.status} onChange={handleChange} >
                                                <option value="aucun_statut">Aucun statut</option>
                                                <option value="actif">Actif</option>
                                                <option value="offre_chaude">Offre chaude</option>
                                                <option value="nouvelle_offre">Nouvelle offre</option>
                                                <option value="portes_ouvertes">Portes ouvertes</option>
                                                <option value="vendu">Vendu</option>
                                            </select> */}
                                        </div>
                                    </div>
                                </div><br />
                                <h2>2. Médias</h2>
                                <h6>Médias de l'annonce</h6>
                                <input
                                    type="file"
                                    id="propertyFiles"
                                    name="image"
                                    multiple
                                    onChange={handleChange}
                                    className="btn theme-btn-3 mb-10"
                                /><br />
                                <p>
                                    <small>* Au moins 1 image est requise pour une soumission valide. Taille minimale : 500/500px.</small><br />
                                    <small>* Les fichiers PDF sont également pris en charge.</small><br />
                                    <small>* Le traitement des images peut prendre un certain temps.</small>
                                </p>
                                <h6>Galerie d'images</h6>
                                <input
                                    type="file"
                                    id="galleryFiles"
                                    name="gallery"
                                    multiple
                                    onChange={handleChange}
                                    className="btn theme-btn-3 mb-10"
                                /><br />
                                <p>
                                    <small>* Vous pouvez ajouter jusqu'à 10 images à la galerie.</small><br />
                                    <small>* Taille maximale par fichier : 5MB.</small><br />
                                    <small>* Le traitement des images peut prendre un certain temps.</small>
                                </p>
                                <h6>Option vidéo</h6>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-item">
                                            <select className="nice-select">
                                                <option>Vidéo de</option>
                                                {/* <option>Vimeo</option> */}
                                                <option>YouTube</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="ltn__name" placeholder="ID de la vidéo intégrée" />
                                        </div>
                                    </div>
                                </div>
                                <h6>Visite virtuelle</h6>
                                <div className="input-item input-item-textarea ltn__custom-icon">
                                    <textarea name="ltn__message" placeholder="Visite virtuelle :" defaultValue={""} />
                                </div>
                                <h2>3. Localisation</h2>
                                <h6>Emplacement de l'annonce</h6>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="adress" placeholder="*Adresse" onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="pays" placeholder="Pays" onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <Select
                                                options={department}
                                                value={department.find(option => option.value === formData.departement)}
                                                placeholder="Selectionnez le département/la région"
                                                onChange={(selectedOption) => {
                                                    console.log(selectedOption);

                                                    setFormData(prevState => ({
                                                        ...prevState,
                                                        departement: selectedOption.value
                                                    }));
                                                }}
                                            />
                                            {/* <input type="text" name="departement" placeholder="Département / Région" onChange={handleChange} /> */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="ville" placeholder="Ville" onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="quartier" placeholder="Quartier" onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="codePostal" placeholder="Code postal" onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="property-details-google-map mb-60">
                                            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9334.271551495209!2d-73.97198251485975!3d40.668170674982946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25b0456b5a2e7%3A0x68bdf865dda0b669!2sBrooklyn%20Botanic%20Garden%20Shop!5e0!3m2!1sen!2sbd!4v1590597267201!5m2!1sen!2sbd" width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} title='unique' /> */}
                                            <MapSelect
                                                longitude={formData.longitude}
                                                latitude={formData.latitude}
                                                onPositionChange={handleMapChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="latitude" placeholder="Latitude (pour Google Maps)" onChange={handleChange} value={latitude} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="longitude" placeholder="Longitude (pour Google Maps)" onChange={handleChange} value={longitude} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="checkbox-item">Activer Google Street View
                                            <input type="checkbox" />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="ltn__name" placeholder="Google Street View - Angle de caméra (valeur de 0 à 360)" />
                                        </div>
                                    </div>
                                </div>
                                <h2>4. Détails</h2>
                                <h6>Détails de l'annonce</h6>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="surface" value={formData.surface} onChange={handleChange} placeholder="Taille en m² (*seulement des chiffres)" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="lotSize" value={formData.lotSize} onChange={handleChange} placeholder="Taille du terrain en m² (*seulement des chiffres)" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="rooms" value={formData.rooms} onChange={handleChange} placeholder="Pièces (*seulement des chiffres)" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="bedrooms" value={formData.bedrooms} onChange={handleChange} placeholder="Chambres (*seulement des chiffres)" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="bathrooms" value={formData.bathrooms} onChange={handleChange} placeholder="Salles de bain (*seulement des chiffres)" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="customId" value={formData.customId} onChange={handleChange} placeholder="ID personnalisé (*texte)" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="garages" value={formData.garages} onChange={handleChange} placeholder="Garages (*texte)" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon"><input type="text" name="yearBuilt" value={formData.yearBuilt} onChange={handleChange} placeholder="Année de construction (*numérique)" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="garageSize" value={formData.garageSize} onChange={handleChange} placeholder="Taille du garage (*texte)" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="availableFrom" value={formData.availableFrom} onChange={handleChange} placeholder="Disponible à partir de (*date)" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="basement" value={formData.basement} onChange={handleChange} placeholder="Sous-sol (*texte)" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="extraDetails" value={formData.extraDetails} onChange={handleChange} placeholder="Détails supplémentaires (*texte)" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="roofing" value={formData.roofing} onChange={handleChange} placeholder="Toiture (*texte)" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="exteriorMaterial" value={formData.exteriorMaterial} onChange={handleChange} placeholder="Matériau extérieur (*texte)" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item">
                                            <Select
                                                options={structure}
                                                value={structure.find(option => option.value === formData.structure)}
                                                placeholder="Type de structure"
                                                onChange={(selectedOption) => {
                                                    console.log(selectedOption);

                                                    setFormData(prevState => ({
                                                        ...prevState,
                                                        structure: selectedOption.value
                                                    }));
                                                }}
                                            />
                                            {/* <select id='structure' className="nice-select" name="structure" value={formData.structure} onChange={handleChange}>
                                                <option value="">Type de structure</option>
                                                <option value="non_disponible">Non disponible</option>
                                                <option value="brique">Brique</option>
                                                <option value="bois">Bois</option>
                                                <option value="ciment">Ciment</option>
                                            </select> */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item">
                                            <Select
                                                options={floorsNo}
                                                value={floorsNo.find(option => option.value === formData.floorsNo)}
                                                placeholder="Nombre d'étages"
                                                onChange={(selectedOption) => {
                                                    console.log(selectedOption);

                                                    setFormData(prevState => ({
                                                        ...prevState,
                                                        floorsNo: selectedOption.value
                                                    }));
                                                }}
                                            />
                                            {/* <select id='floorsNo' className="nice-select" name="floorsNo" value={formData.floorsNo} onChange={handleChange}>
                                                <option value="">Nombre d'étages</option>
                                                <option value="non_disponible">Non disponible</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select> */}
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <br />
                                        {/* <br /> */}
                                        <div className="input-item input-item-textarea ltn__custom-icon">
                                            <textarea name="notes" value={formData.notes} onChange={handleChange} placeholder="Notes du propriétaire/agent (*non visibles sur le site)" />
                                        </div>
                                    </div>
                                </div>
                                <h6>Sélectionner la classe énergétique</h6>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-item">
                                            <Select
                                                options={energyClass}
                                                value={energyClass.find(option => option.value === formData.energyClass)}
                                                placeholder="Sélectionner la classe énergétique (réglementation UE)"
                                                onChange={(selectedOption) => {
                                                    console.log(selectedOption);

                                                    setFormData(prevState => ({
                                                        ...prevState,
                                                        energyClass: selectedOption.value
                                                    }));
                                                }}
                                            />
                                            {/* <select
                                                id="energyClass"
                                                className="nice-select"
                                                name="energyClass"
                                                value={formData.energyClass}
                                                onChange={handleChange}
                                            >
                                                <option value={""}>Sélectionner la classe énergétique (réglementation UE)</option>
                                                <option value={"A+"}>A+</option>
                                                <option value={"A"}>A</option>
                                                <option value={"B"}>B</option>
                                                <option value={"C"}>C</option>
                                                <option value={"D"}>D</option>
                                                <option value={"E"}>E</option>
                                            </select> */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input type="text" name="energyIndex" value={formData.energyIndex} onChange={handleChange} placeholder="Indice énergétique en kWh/m²a" />
                                        </div>
                                    </div>
                                </div>
                                <h2>5. Équipements</h2>
                                <h6>Équipements et caractéristiques</h6>
                                <h6>Détails intérieurs</h6>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">Cuisine équipée
                                            <input type="checkbox" name="interiorEquipments" value="Cuisine équipée" onChange={handleChange} />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">Salle de sport
                                            <input type="checkbox" name="interiorEquipments" value="Salle de sport" onChange={handleChange} />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">Buanderie
                                            <input type="checkbox" name="interiorEquipments" value="Buanderie" onChange={handleChange} />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">Salle multimédia
                                            <input type="checkbox" name="interiorEquipments" value="Salle multimédia" onChange={handleChange} />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                </div>
                                <h6 className="mt-20">Détails extérieurs</h6>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">Jardin
                                            <input type="checkbox" name='exteriorEquipments' value="Jardin" onChange={handleChange} />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">Terrain de basket
                                            <input type="checkbox" name='exteriorEquipments' value="Terrain de basket" onChange={handleChange} />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">Terrasse
                                            <input type="checkbox" name='exteriorEquipments' value="Terrasse" onChange={handleChange} />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">Garage attenant
                                            <input type="checkbox" name='exteriorEquipments' value="Garage attenant" onChange={handleChange} />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">Jacuzzi
                                            <input type="checkbox" name='exteriorEquipments' value="Jacuzzi" onChange={handleChange} />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">Piscine
                                            <input type="checkbox" name="exteriorEquipments" value="Piscine" onChange={handleChange} />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                </div>

                                <h6 className="mt-20">Équipements</h6>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">Climatisation centrale
                                            <input type="checkbox" name="equipments" value="Climatisation centrale" onChange={handleChange} />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">Électricité
                                            <input type="checkbox" name="equipments" value="Électricité" onChange={handleChange} />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">Chauffage
                                            <input type="checkbox" name="equipments" value="Chauffage" onChange={handleChange} />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">Gaz naturel
                                            <input type="checkbox" name="equipments" value="Gaz naturel" onChange={handleChange} />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">Ventilation
                                            <input type="checkbox" name="equipments" value="Ventilation" onChange={handleChange} />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">Eau
                                            <input type="checkbox" name="equipments" value="Eau" onChange={handleChange} />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                </div>

                                <h6 className="mt-20">Autres caractéristiques</h6>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">Accès fauteuil roulant
                                            <input type="checkbox" name="caracteristics" value="Accès fauteuil roulant" onChange={handleChange} />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">Ascenseur
                                            <input type="checkbox" name="caracteristics" value="Ascenseur" onChange={handleChange} />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">Cheminée
                                            <input type="checkbox" name="caracteristics" value="Cheminée" onChange={handleChange} />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">Détecteurs de fumée
                                            <input type="checkbox" name="caracteristics" value="Détecteurs de fumée" onChange={handleChange} />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">Machine à laver et sèche-linge
                                            <input type="checkbox" name="caracteristics" value="Machine à laver et sèche-linge" onChange={handleChange} />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <label className="checkbox-item">WiFi
                                            <input type="checkbox" name="caracteristics" value="WiFi" onChange={handleChange} />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                </div>

                                <h2>Transport</h2>
                                <h6>Lignes metro</h6>
                                <div className="row">
                                    {metros.Metros?.map((metro, index) => (
                                        <div className="col-lg-4 col-md-6" key={index}>
                                            <label className="checkbox-item">Ligne {metro.line}
                                                <input type="checkbox" name="metro" value={metro._id} onChange={handleChange} />
                                                <span className="checkmark" />
                                            </label>
                                        </div>
                                    ))}
                                </div>

                                <h6 className="mt-20">Lignes RER</h6>
                                <div className="row">
                                    {rers.Rers?.map((rer, index) => (
                                        <div className="col-lg-4 col-md-6" key={index}>
                                            <label className="checkbox-item">Ligne {rer.line}
                                                <input type="checkbox" name="rer" value={rer._id} onChange={handleChange} />
                                                <span className="checkmark" />
                                            </label>
                                        </div>
                                    ))}
                                </div>

                                <h6 className="mt-20">Lignes Tramway</h6>
                                <div className="row">
                                    {tramways.Trams?.map((tramway, index) => (
                                        <div className="col-lg-4 col-md-6" key={index}>
                                            <label className="checkbox-item">Ligne {tramway.line}
                                                <input type="checkbox" name="tramway" value={tramway._id} onChange={handleChange} />
                                                <span className="checkmark" />
                                            </label>
                                        </div>
                                    ))}

                                </div>
                                <div className="alert alert-warning d-none" role="alert">
                                    Veuillez noter que la date et l'heure que vous avez demandées peuvent ne pas être disponibles. Nous vous contacterons pour confirmer les détails réels de votre rendez-vous.
                                </div>
                                <div className="btn-wrapper text-center mt-30">
                                    <button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">Soumettre la propriété</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddListing;