import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
const BASE_URL = "/api"

// Create Review
export const createReview = createAsyncThunk(
  'reviews/create',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/review`, formData);
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Could not submit review.');
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Fetch Review by ID
export const fetchReviewById = createAsyncThunk(
  'reviews/getById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/review/${id}`);
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Could not get review.');
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getReviewsByAppartmentId = createAsyncThunk(
  'reviews/getByAppartmentId',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.get(`${BASE_URL}/review/appartment/${id}`);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.message || error.message);
      } else {
        return rejectWithValue("I Think Something went wrong!");
      }
    }
  }
);


export const fetchTopRatedReviews = createAsyncThunk(
  'reviews/fetchTopRatedReviews',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/reviews/rating`); // Assurez-vous que l'API renvoie les appartements
      return response.data; // Retourner les appartements depuis la réponse
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);