import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV4 extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 align-self-center">
						<div className="about-us-img-wrap about-img-left">
							<img src={publicUrl + "assets/img/others/abt.jpg"} alt="About Us Image" />
							<div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
								<div className="ltn__video-img ltn__animation-pulse1">
									{/* <img src={publicUrl+"assets/img/others/8.png"} alt="video popup bg image" />
							<a className="ltn__video-icon-2 ltn__video-icon-2-border---" href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0" data-rel="lightcase:myCollection">
							<i className="fa fa-play" />
							</a> */}
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-6 align-self-center">
						<div className="about-us-info-wrap">
							<div className="section-title-area ltn__section-title-2--- mb-20">
								<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">About Us</h6>
								<h1 className="section-title">Experts en immobilier
									en Île-de-France<span>.</span></h1>
								<p>Chez Appartement 9, nous nous spécialisons dans la vente
									d’appartements en Île-de-France. Nous proposons une large
									gamme de logements modernes adaptés à tous les styles de vie.
									Que vous recherchiez un studio cosy, un appartement de deux chambres ou un espace plus vaste pour votre famille, notre sélection unique de biens immobiliers saura répondre à vos besoins. Notre équipe dévouée est là pour vous accompagner à chaque étape de votre projet immobilier, en vous offrant des conseils personnalisés et un service client exceptionnel.
								</p>
							</div>

							<div className="ltn__callout bg-overlay-theme-05  mt-30">
								<p ><strong style={{ fontSize: '20px', fontWeight: 'bold' }}>Notre mission</strong> <br />

									Offrir un service de qualité pour aider nos clients à trouver l'appartement idéal<br /> en Île-de-France,
									en simplifiant le processus de recherche <br /> et en fournissant des conseils personnalisés." </p>
							</div>

							<div className="ltn__callout bg-overlay-theme-05  mt-30">
								<p ><strong style={{ fontSize: '20px', fontWeight: 'bold' }}>Notre Vision</strong> <br />

									Nous aspirons à transformer le paysage immobilier en Île-de-France grâce à notre <br /> engagement envers
									la satisfaction client et notre capacité à concrétiser <br /> les rêves de logement. </p>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	}
}

export default AboutV4