import { Link, useHistory } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import emailjs from '@emailjs/browser';
import { useAlert } from 'react-alert';
import { actGetPropertyCounts } from '../../slices/appartement/AppartSlice';

function Sidebar() {
	let publicUrl = process.env.PUBLIC_URL + '/';
	const dispatch = useDispatch();
	const recentActualites = useSelector(state => state.actualites.recentItems);
	const loading = useSelector(state => state.actualites.status);
	const error = useSelector(state => state.actualites.error);
	const [propertyCounts, setPropertyCounts] = useState({});

	const form = useRef();
	const alert = useAlert();
	const [emailStatus, setEmailStatus] = useState("");
	const history = useHistory();
	const popularProperties = useSelector(state => state.appartement.popularProperties) || [];
	console.log("popularProperties: ", popularProperties);
	// Déplacez `useEffect` avant tout retour conditionnel
	useEffect(() => {
		dispatch(actGetPropertyCounts())
			.then(response => setPropertyCounts(response.payload))
			.catch(err => console.error("Failed to fetch property counts:", err));
	}, [dispatch]);

	if (loading === 'loading') return <div>Chargement...</div>;
	if (error) return <div>Erreur : {error}</div>;
	if (popularProperties.loading === 'loading') return <div>Chargement...</div>;
	if (popularProperties.error) return <div>Erreur : {popularProperties.error}</div>;







	const sendEmail = async (e) => {
		e.preventDefault();

		emailjs.sendForm("service_v1afctl", "template_ytp08gz", form.current, {
			publicKey: "5nSvvdSIRS4sqijml",
		})
			.then(
				() => {
					history.push('/confirmation', { message: "Votre message a été envoyé avec succès." }); // Redirection vers la page de confirmation
				},
				(error) => {
					setEmailStatus("Échec de l'envoi du message. Veuillez réessayer."); // Message d'erreur en cas d'échec
					console.log("EmailJS Error: ", error.text);
				}
			);
	};

	return (
		<div className="col-lg-4 go-top">
			<aside className="sidebar-area blog-sidebar ltn__right-sidebar">
				{/* Form Widget */}
				<div className="widget ltn__form-widget">
					<h4 className="ltn__widget-title ltn__widget-title-border-2">Laissez un Message Pour Réserver</h4>
					<form ref={form} onSubmit={sendEmail}>
						<input
							type="text"
							id="nom"
							name="nom"
							placeholder="Votre Nom*"
							required
						/>
						<input
							type="email"
							id="email"
							name="email"
							placeholder="Votre e-Mail*"
							required
						/>
						<textarea
							id="message"
							name="message"
							placeholder="Écrivez un Message..."
							required
						></textarea>
						<button type="submit" className="btn theme-btn-1">Envoyer le Message</button>
					</form>
				</div>

				{/* Top Rated Product Widget */}
				<div className="widget ltn__popular-product-widget">
					<h4 className="ltn__widget-title ltn__widget-title-border-2">Propriétés Populaires</h4>
					<div className="row ltn__popular-product-widget-active slick-arrow-1">
						{popularProperties.length > 0 ? (
							popularProperties.slice(0, 2).map((property, index) => (
								<div className="col-12" key={index}>
									<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
										<div className="product-img">
											<Link to={`/product-details/${property._id}`}>
												<img src={property.image} alt="#" />
											</Link>

										</div>
										<div className="product-info">
											<div className="product-price">
												<span> {property.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || 'Prix non disponible'} €</span>

											</div>
											<h2 className="product-title">
												<Link to={`/product-details/${property._id}`}>
													{property.title}
												</Link>
											</h2>
											<div className="product-img-location">
												<ul>
													<li>
														<Link to={`/product-details/${property._id}`}>
															<i className="flaticon-pin" /> {property.location}
														</Link>
													</li>
												</ul>
											</div>
											<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
												<li><span>{property.bedrooms} </span>Chambres</li>
												<li><span>{property.bathrooms} </span>Salles de Bain</li>
												<li><span>{property.surface} </span>m²</li>
											</ul>
										</div>
									</div>
								</div>
							))
						) : (
							<p>No popular properties available.</p>
						)}
					</div>
				</div>

				{/* Menu Widget (Category) */}
				<div className="widget ltn__menu-widget ltn__menu-widget-2--- ltn__menu-widget-2-color-2---">
					<h4 className="ltn__widget-title ltn__widget-title-border-2">Catégories Principales</h4>
					<ul className="go-top">
						<li><Link to="/blog-grid">Appartements <span>({propertyCounts.Appartement || 0})</span></Link></li>
						<li><Link to="/blog-grid">Studio<span>({propertyCounts.Studio || 0})</span></Link></li>
						<li><Link to="/blog-grid">Villas de Luxe <span>({propertyCounts.Villas || 0})</span></Link></li>
						<li><Link to="/blog-grid">Maison <span>({propertyCounts.Maison || 0})</span></Link></li>
					</ul>
				</div>


				{/* Popular Post Widget */}
				<div className="widget ltn__popular-post-widget">
					<h4 className="ltn__widget-title ltn__widget-title-border-2">Derniers articles</h4>
					<ul>
						{recentActualites.slice(0, 2).map((article, index) => (
							<li key={article._id || index}>
								<div className="popular-post-widget-item clearfix">
									<div className="popular-post-widget-img">
										<Link to={`/blog-details/${article._id}`}>
											<img src={article.image || publicUrl + "assets/img/team/5.jpg"} alt={article.title} />
										</Link>
									</div>
									<div className="popular-post-widget-brief">
										<h6>
											<Link to={`/blog-details/${article._id}`}>{article.title}</Link>
										</h6>
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date">
													<Link to="#"><i className="far fa-calendar-alt" />
														{new Date(article.date).toLocaleDateString('fr-FR', {
															year: 'numeric',
															month: 'long',
															day: 'numeric'
														})}
													</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</li>
						))}
					</ul>
				</div>

				{/* Social Media Widget */}
				<div className="widget ltn__social-media-widget">
					<h4 className="ltn__widget-title ltn__widget-title-border-2">Suivez-nous</h4>
					<div className="ltn__social-media-2">
						<ul>
							<li><a href="#" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
							<li><a href="#" title="Twitter"><i className="fab fa-twitter" /></a></li>
							<li><a href="#" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
							<li><a href="#" title="Instagram"><i className="fab fa-instagram" /></a></li>
						</ul>
					</div>
				</div>



				{/* Banner Widget */}
				<div className="widget ltn__banner-widget d-none go-top">
					<Link to="/shop"><img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" /></Link>
				</div>
			</aside>
		</div>
	);
}

export default Sidebar;