import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { actGetAppartements } from '../../slices/appartement/AppartSlice';
import { useDispatch, useSelector } from 'react-redux';
import MapDialog from './MapDialog';

const BannerV6 = () => {
    const publicUrl = process.env.PUBLIC_URL + '/';
    const history = useHistory();
    const [showMapDialog, setShowMapDialog] = useState(false);
    const [departement, setDepartement] = useState('');
    const [rooms, setRooms] = useState(''); // Toujours commencer avec une chaîne vide

    const dispatch = useDispatch();
    const { records } = useSelector(state => state.appartement);

    useEffect(() => {
        dispatch(actGetAppartements());
    }, [dispatch]);

    const roomOptions = [
        { value: '', label: 'Nombre de pièces' },  // Option par défaut
        { value: '0', label: 'Studio' },  // Pour les studios
        { value: '1', label: '1 pièce' },
        { value: '2', label: '2 pièces' },
        { value: [3, Infinity], label: '3 pièces ou plus' }, // Utilisation d'un tableau pour les 3 pièces ou plus
    ];

    const Departement = [
        { value: '', label: 'Département' },
        { value: 'Paris', label: 'Paris (75)' },
        { value: 'Seine-et-Marne', label: 'Seine-et-Marne (77)' },
        { value: 'Yvelines', label: 'Yvelines (78)' },
        { value: 'Essonne', label: 'Essonne (91)' },
        { value: 'Hauts-de-Seine', label: 'Hauts-de-Seine (92)' },
        { value: 'Seine-Saint-Denis', label: 'Seine-Saint-Denis (93)' },
        { value: 'Val-de-Marne', label: 'Val-de-Marne (94)' },
    ];

    const handleMapClick = () => {
        setShowMapDialog(true);
    };

    const handleCloseMapDialog = () => {
        setShowMapDialog(false);
    };

    const handleSearch = (e) => {
        e.preventDefault();

        let roomsQuery;
        if (Array.isArray(rooms)) {
            // Si c'est une plage (par exemple, [3, Infinity])
            roomsQuery = `${rooms[0]}-`; // Le '-' indique que c'est '3 et plus'
        } else if (rooms !== '') {
            // Si c'est une valeur exacte (par exemple, '0', '1', '2')
            roomsQuery = rooms;
        } else {
            roomsQuery = '';
        }

        // Construire l'URL en fonction des filtres sélectionnés
        let url = `/shop-right-sidebar?`;
        if (departement) {
            url += `departement=${departement}&`;
        }
        if (roomsQuery !== '') {
            url += `rooms=${roomsQuery}`;
        }

        history.push(url);
    };

    // Appliquez `customStyles` à tous les champs de sélection pour qu'ils soient cohérents
    const customStyles = {
        control: (provided) => ({
            ...provided,
            minHeight: '60px',
            height: '60px',
            borderRadius: '0',
            border: '1px solid #e5e5e5',
            boxShadow: 'none',
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: '60px',
            padding: '0 15px',
            display: 'flex',
            alignItems: 'center',
        }),
        input: (provided) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            height: '60px',
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: '16px',
            color: '#777',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '16px',
            color: '#222',
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: '16px',
            color: state.isSelected ? '#fff' : '#222',
            backgroundColor: state.isSelected ? '#222' : state.isFocused ? '#f0f0f0' : null,
            '&:active': {
                backgroundColor: '#222',
                color: '#fff',
            },
        }),
    };

    return (
        <div className="ltn__slider-area ltn__slider-4 position-relative ltn__primary-bg">
            <div className="ltn__slide-one-active----- slick-slide-arrow-1----- slick-slide-dots-1----- arrow-white----- ltn__slide-animation-active" style={{ position: 'relative' }}>
                <video autoPlay muted loop id="myVideo">
                    <source src={publicUrl + "assets/media/4.mp4"} type="video/mp4" />
                </video>
                <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-7 bg-image--- bg-overlay-theme-black-30" data-bs-bg={publicUrl + "assets/img/slider/41.jpg"}>
                    <div className="ltn__slide-item-inner text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 align-self-center">
                                    <div className="slide-item-car-dealer-form">
                                        <div className="section-title-area ltn__section-title-2 text-center">
                                            <h1 className="section-title text-color-white">Votre <span className="ltn__secondary-color-3">nouveau</span> chez-vous en Île-de-France vous attend !</h1>
                                        </div>
                                        <div className="ltn__car-dealer-form-tab">
                                            <div className="tab-content pb-12">
                                                <div className="tab-pane fade active show" id="ltn__form_tab_1_1">
                                                    <div className="car-dealer-form-inner">
                                                        <form onSubmit={handleSearch} className="ltn__car-dealer-form-box row">
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-3 col-md-6">
                                                                <Select
                                                                    options={roomOptions}
                                                                    onChange={(e) => setRooms(e.value)}
                                                                    styles={customStyles}  // Appliquer customStyles
                                                                    placeholder="Nombre de pièces"
                                                                />
                                                            </div>
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter col-lg-3 col-md-6">
                                                                <Select
                                                                    options={Departement}
                                                                    onChange={(e) => setDepartement(e.value)}
                                                                    styles={customStyles}  // Appliquer customStyles
                                                                    placeholder="Département"
                                                                />
                                                            </div>
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-3 col-md-6">
                                                                <div className="btn-wrapper text-center mt-0 go-top">
                                                                    <button type="submit" className="btn theme-btn-1 btn-effect-1 text-uppercase">Rechercher</button>
                                                                </div>
                                                            </div>
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-3 col-md-6">
                                                                <div className="btn-wrapper text-center mt-0 go-top">
                                                                    <button onClick={handleMapClick} type="button" className="btn theme-btn-1 btn-effect-1 text-uppercase">Afficher la carte</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MapDialog
                show={showMapDialog}
                onClose={handleCloseMapDialog}
                properties={records}
            />
        </div>
    );
}

export default BannerV6;
