import { Link, useParams, useHistory } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import emailjs from '@emailjs/browser';
import { fetchTopRatedReviews } from '../../slices/review/ReviewSlice';
const Sidebar = ({ onFilterChange }) => {
  const { id: productId } = useParams();
  const dispatch = useDispatch();
  const [selectedPriceRange, setSelectedPriceRange] = useState([0, Infinity]);
  const [selectedSurfaceRange, setSelectedSurfaceRange] = useState([0, Infinity]);
  const topRatedApartments = useSelector((state) => state.reviews.topRated);

  const priceRanges = [
    { label: '0 - 100,000 €', value: [0, 100000] },
    { label: '100,000 - 200,000 €', value: [100000, 200000] },
    { label: '200,000 - 500,000 €', value: [200000, 500000] },
    { label: '500,000 - 1,000,000 €', value: [500000, 1000000] },
    { label: '1,000,000+ €', value: [1000000, Infinity] },
  ];
  let publicUrl = process.env.PUBLIC_URL + '/';

  const surfaceRanges = [
    { label: '0 - 50 m²', value: [0, 50] },
    { label: '50 - 100 m²', value: [50, 100] },
    { label: '100 - 200 m²', value: [100, 200] },
    { label: '200 - 500 m²', value: [200, 500] },
    { label: '500+ m²', value: [500, Infinity] },
  ];


  useEffect(() => {

    // Fetch top-rated apartments
    dispatch(fetchTopRatedReviews())
      .then(response => {
        if (response.error) {
          console.error("Failed to fetch top-rated apartments:", response.error);
        } else {
          console.log("Top Rated Apartments:", response.payload);  // Ajouter cette ligne
        }
      })
      .catch(err => console.error("Failed to fetch top-rated apartments:", err));


  }, [dispatch, productId]);




  const handlePriceChange = (range) => {
    if (JSON.stringify(selectedPriceRange) === JSON.stringify(range)) {
      setSelectedPriceRange([0, Infinity]);
      onFilterChange({ priceRange: [0, Infinity], surfaceRange: selectedSurfaceRange });
    } else {
      setSelectedPriceRange(range);
      onFilterChange({ priceRange: range, surfaceRange: selectedSurfaceRange });
    }
  };

  const handleSurfaceChange = (range) => {
    if (JSON.stringify(selectedSurfaceRange) === JSON.stringify(range)) {
      setSelectedSurfaceRange([0, Infinity]);
      onFilterChange({ priceRange: selectedPriceRange, surfaceRange: [0, Infinity] });
    } else {
      setSelectedSurfaceRange(range);
      onFilterChange({ priceRange: selectedPriceRange, surfaceRange: range });
    }
  };

  return (
    <div className="col-lg-4 mb-100">
      <aside className="sidebar ltn__shop-sidebar">

        {/* Style ajouté directement pour le titre du sidebar */}
        {/* Titre principal pour les filtres */}


        <div className="ltn__callout bg-overlay-theme-05  mt-30">
          <p>

            Affinez votre recherche avec le prix <br />et la surface Trouvez l'appartement parfait <br />
            en ajustant les critères de prix et de surface. </p>
        </div>



        {/* Price Filter */}
        <div className="widget ltn__price-filter-widget">
          <h4 style={styles.filterTitle}>Prix</h4>
          <ul>
            {priceRanges.map((range, index) => (
              <li key={index}>
                <label className="checkbox-item">
                  {range.label}
                  <input
                    type="checkbox"
                    checked={JSON.stringify(selectedPriceRange) === JSON.stringify(range.value)}
                    onChange={() => handlePriceChange(range.value)}
                  />
                  <span className="checkmark" />
                </label>
              </li>
            ))}
          </ul>
        </div>

        {/* Surface Filter */}
        <div className="widget ltn__price-filter-widget">
          <h4 style={styles.filterTitle}>Surface (m²)</h4>
          <ul>
            {surfaceRanges.map((range, index) => (
              <li key={index}>
                <label className="checkbox-item">
                  {range.label}
                  <input
                    type="checkbox"
                    checked={JSON.stringify(selectedSurfaceRange) === JSON.stringify(range.value)}
                    onChange={() => handleSurfaceChange(range.value)}
                  />
                  <span className="checkmark" />
                </label>
              </li>
            ))}
          </ul>
        </div>
      </aside>
      <br />
      <br />
      {/* Other widgets */}
      <div className="widget ltn__top-rated-product-widget go-top">
        <h4 className="ltn__widget-title ltn__widget-title-border-2">Produits Les Mieux Notés</h4>
        <ul>
          {topRatedApartments && topRatedApartments.length > 0 ? (
            topRatedApartments.map((apartment, index) => (
              <li key={index}>
                <div className="top-rated-product-item clearfix">
                  <div className="top-rated-product-img">
                    <Link to={`/product-details/${apartment._id}`}>
                      <img src={apartment.image || 'default-image.jpg'} alt={apartment.title || 'Product Title'} />
                    </Link>
                  </div>
                  <div className="top-rated-product-info">
                    <div className="product-ratting" style={{ marginBottom: '10px' }}> {/* Ajoute un espacement avec marginBottom */}
                      <ul style={{ display: 'flex', gap: '5px' }}> {/* Ajoute un espace entre les étoiles */}
                        {Array.from({ length: Math.floor(apartment.averageRating) }, (_, index) => (
                          <li key={index}><i className="fas fa-star" /></li>
                        ))}
                        {apartment.averageRating % 1 !== 0 && <li><i className="fas fa-star-half-alt" /></li>}
                      </ul>
                    </div>
                    <h6><Link to={`/product-details/${apartment._id}`}>{apartment.title || 'Titre non disponible'}</Link></h6>
                    <div className="product-price">
                      <span> {apartment.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || 'Prix non disponible'} €</span>

                    </div>
                  </div>

                </div>
              </li>
            ))
          ) : (
            <p>No top-rated apartments available.</p>
          )}
        </ul>
      </div>

      {/* Social Media Widget */}
      <h4 className="ltn__widget-title ltn__widget-title-border-2">Suivez-nous sur les réseaux sociaux</h4>

      <div className="widget ltn__social-media-widget">
        <div className="ltn__social-media-2">
          <ul>
            <li><a href="#" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
            <li><a href="#" title="Twitter"><i className="fab fa-twitter" /></a></li>
            <li><a href="#" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
            <li><a href="#" title="Instagram"><i className="fab fa-instagram" /></a></li>
          </ul>
        </div>
      </div>
    </div>

  );
};

// Ajout des styles directement dans le composant
const styles = {
  sidebarTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#304798', // Couleur personnalisée
    textAlign: 'center', // Centré
  },
  filterTitle: {
    fontSize: '15px',
    marginBottom: '15px',
    color: 'black', // Couleur personnalisée pour les sous-titres
  },
  sidebarMainTitle: {
    fontSize: '24px',
    color: '#304798',
    marginBottom: '10px',
  },
  sidebarSubtitle: {
    fontSize: '16px',
    color: '#555',
  },

};

export default Sidebar;
