import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import AOS from 'aos';
import 'aos/dist/aos.css';

class CaracteristiquesV2 extends Component {
    componentDidMount() {
        AOS.init({
            duration: 1000,
            once: false,
        });
    }

    render() {
        let publicUrl = process.env.PUBLIC_URL + '/';
        let classePersonnalisee = this.props.customClass ? this.props.customClass : '';

        return (
            <div className={classePersonnalisee}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title-area ltn__section-title-2--- text-center">
                                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Nos Services</h6>
                                <h1 className="section-title">Ce que nous offrons </h1>
                            </div>
                        </div>
                    </div>
                    <div className="row ltn__custom-gutter--- justify-content-center go-top">
                        <div className="col-lg-4 col-sm-6 col-12" data-aos="fade-up">
                            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white box-shadow-1 card-animation" style={{ height: '550px', display: 'flex', flexDirection: 'column', position: 'relative' }}>
                                <div className="ltn__feature-icon">
                                    <video 
                                        autoPlay 
                                        muted 
                                        loop 
                                        playsInline
                                        style={{ width: '100%', maxWidth: '340px', height: 'auto' }}
                                    >
                                        <source src={publicUrl + "assets/media/11.mp4"} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                                <div className="ltn__feature-info" style={{ flex: 1 }}>
                                    <h3><Link to="/service-details">Appartements modernes</Link></h3>
                                    <p>Nous proposons une gamme variée de logements modernes pour tous les styles de vie. Que vous cherchiez un studio cosy ou un appartement spacieux, nos biens sont situés dans les meilleurs quartiers de l'Île-de-France, offrant confort et commodités à proximité.</p>
                                </div>
                                <div style={{ position: 'absolute', bottom: '20px', left: '0', right: '0' }}>
                                    <Link className="ltn__service-btn" to="/shop">Trouver une maison <i className="flaticon-right-arrow" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12" data-aos="fade-up" data-aos-delay="200">
                            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white box-shadow-1 card-animation active" style={{ height: '550px', display: 'flex', flexDirection: 'column', position: 'relative' }}>
                                <div className="ltn__feature-icon">
                                    <video 
                                        autoPlay 
                                        muted 
                                        loop 
                                        playsInline
                                        style={{ width: '100%', maxWidth: '340px', height: 'auto' }}
                                    >
                                        <source src={publicUrl + "assets/media/12.mp4"} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                                <div className="ltn__feature-info" style={{ flex: 1 }}>
                                    <h3><Link to="/service-details">Accompagnement personnalisé</Link></h3>
                                    <p>De la première visite à la remise des clés, nous vous accompagnons à chaque étape. Notre équipe dévouée comprend vos besoins spécifiques et vous offre des conseils personnalisés pour trouver le logement idéal. Nous sommes là pour vous guider et vous rassurer tout au long du processus.</p>
                                </div>
                                <div style={{ position: 'absolute', bottom: '20px', left: '0', right: '0' }}>
                                    <Link className="ltn__service-btn" to="/shop">Trouver une maison <i className="flaticon-right-arrow" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12" data-aos="fade-up" data-aos-delay="400">
                            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white box-shadow-1 card-animation" style={{ height: '550px', display: 'flex', flexDirection: 'column', position: 'relative' }}>
                                <div className="ltn__feature-icon">
                                    <video 
                                        autoPlay 
                                        muted 
                                        loop 
                                        playsInline
                                        style={{ width: '100%', maxWidth: '340px', height: 'auto'}}
                                    >
                                        <source src={publicUrl + "assets/media/13.mp4"} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                                <div className="ltn__feature-info" style={{ flex: 1 }}>
                                    <h3><Link to="/service-details">Financement sur mesure</Link></h3>
                                    <p>Nous collaborons avec les meilleurs établissements financiers pour vous proposer des solutions de financement adaptées. Que vous soyez primo-accédant ou investisseur, nous vous aidons à obtenir le financement idéal pour concrétiser votre projet immobilier. Notre objectif est de rendre votre achat aussi fluide et sans stress que possible.</p>
                                </div>
                                <div style={{ position: 'absolute', bottom: '20px', left: '0', right: '0' }}>
                                    <Link className="ltn__service-btn" to="/shop">Trouver une maison <i className="flaticon-right-arrow" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CaracteristiquesV2;