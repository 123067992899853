import React, { useEffect, useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useHistory } from 'react-router-dom';

const ContactForm = () => {
	const form = useRef();
	const [emailStatus, setEmailStatus] = useState("");
	const history = useHistory();

	const sendEmail = async (e) => {
		e.preventDefault();

		emailjs.sendForm("service_v1afctl", "template_ytp08gz", form.current, {
			publicKey: "5nSvvdSIRS4sqijml",
		})
			.then(
				() => {
					history.push('/confirmation', { message: "Votre message a été envoyé avec succès." }); // Redirection vers la page de confirmation
				},
				(error) => {
					setEmailStatus("Échec de l'envoi du message. Veuillez réessayer."); // Message d'erreur en cas d'échec
					console.log("EmailJS Error: ", error.text);
				}
			);
	};

	let publicUrl = process.env.PUBLIC_URL + '/'

	return (
		<div className="ltn__contact-message-area mb-120 mb--100">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="ltn__form-box contact-form-box box-shadow white-bg">
							<h4 className="title-2">Obtenez un Devis</h4> {/* Traduction du titre */}
							<form id="contact-form" ref={form} onSubmit={sendEmail}>
								<div className="row">
									<div className="col-md-6">
										<div className="input-item input-item-name ltn__custom-icon">
											<input
												type="text"
												id="nom"
												name="nom"
												placeholder="Votre Nom*"
												required
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="input-item input-item-email ltn__custom-icon">
											<input
												type="email"
												id="email"
												name="email"
												placeholder="Votre e-Mail*"
												required
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="input-item input-item-phone ltn__custom-icon">
											<input
												type="text"
												id="phone"
												name="phone"
												placeholder="Votre numéro de téléphone"
											/>
										</div>
									</div>
								</div>
								<div className="input-item input-item-textarea ltn__custom-icon">
									<textarea
										id="message"
										name="message"
										placeholder="Écrivez un Message..."
										required
									></textarea>
								</div>
								<div className="btn-wrapper mt-0">
									<button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">obtenez un service gratuit</button> {/* Traduction du bouton */}
								</div>
								<p className="form-messege mb-0 mt-20" />
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ContactForm;
