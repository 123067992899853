import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const BASE_URL = "/api"

export const actLogin = createAsyncThunk(
    "auth/actLogin",
    async (formData, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await axios.post(`${BASE_URL}/auth/login`, {
                email: formData.email,
                password: formData.password,
            });
            // console.log("response", response.data);
            localStorage.setItem("token", JSON.stringify(response.data.token));
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.message || error.message);
            } else {
                return rejectWithValue("I Think Something went wrong!");
            }
        }
    }
);


