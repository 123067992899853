import { configureStore } from "@reduxjs/toolkit";
import appartementReducer from "../slices/appartement/AppartSlice";
import authReducer from "../slices/Auth/AuthSlices";
import { actualitesReducer } from "../slices/actualite/ActualiteSlice";
import reviewReducer from "../slices/review/ReviewSlice.jsx";

export const store = configureStore({
    reducer: {
        appartement: appartementReducer,
        auth: authReducer,
        actualites: actualitesReducer,
        reviews: reviewReducer
    },
});