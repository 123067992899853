import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
const BASE_URL = "/api"


export const actGetAppartements = createAsyncThunk(
    "appart/actGetAppartements",
    async (params = '', thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await axios.get(`${BASE_URL}/appartements?${params}`);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data || error.message);
            } else {
                return rejectWithValue("Something went wrong!");
            }
        }
    }
);

export const actGetPropertyCounts = createAsyncThunk(
    "appart/actGetPropertyCounts",
    async (_, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await axios.get(`${BASE_URL}/appartements/property/counts`);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data || error.message);
            } else {
                return rejectWithValue("Something went wrong!");
            }
        }
    }
);









export const actGetAppartement = createAsyncThunk(
    "appart/actGetAppartement",
    async (id, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await axios.get(`${BASE_URL}/appartements/${id}`);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.message || error.message);
            } else {
                return rejectWithValue("I Think Something went wrong!");
            }
        }
    }
);

export const actCreateAppartement = createAsyncThunk(
    "appart/actCreateAppartement",
    async (formData, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await axios.post(`${BASE_URL}/appartements`, formData);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.message || error.message);
            } else {
                return rejectWithValue("I Think Something went wrong!");
            }
        }
    }
);

export const actUpdateAppartement = createAsyncThunk(
    "appart/actUpdateAppartement",
    async ({ id, formData }, thunkAPI) => { // Ajout de l'ID comme paramètre
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await axios.put(`${BASE_URL}/appartements/${id}`, formData);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.message || error.message);
            } else {
                return rejectWithValue("I Think Something went wrong!");
            }
        }
    }
);



export const actGetPopularProperties = createAsyncThunk(
    "appart/actGetPopularProperties",
    async (_, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await axios.get(`${BASE_URL}/appartements/popular`);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data || error.message);
            } else {
                return rejectWithValue("Something went wrong!");
            }
        }
    }
);


export const actDeleteAppartement = createAsyncThunk(
    "appart/actDeleteAppartement",
    async (id, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await axios.delete(`${BASE_URL}/appartements/${id}`);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.message || error.message);
            } else {
                return rejectWithValue("I Think Something went wrong!");
            }
        }
    }
);

export const actfindNearbyApartments = createAsyncThunk(
    "appart/findNearbyApartments",
    async (id, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await axios.get(`${BASE_URL}/appartements/nearby/${id}`);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data || error.message);
            } else {
                return rejectWithValue("Something went wrong!");
            }
        }
    }
);
