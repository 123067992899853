import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ContactInfo from './section-components/contact-info';
import ContactForm from './section-components/contact-form';
import Map from './section-components/map';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const ContactV1 = () => {
    return (
        <div>
            <Helmet>
                <title>Contactez Appart9 | Service Client Immobilier en Île-de-France</title>
                <meta name="description" content="Contactez Appart9 pour toutes vos questions immobilières en Île-de-France. Notre équipe de support est là pour vous aider dans votre projet d'achat ou de vente." />
                <meta name="keywords" content="Contactez-nous, Informations de contact, Formulaire de contact, Service client, Support immobilier, Appart9, Île-de-France" />
                <meta property="og:title" content="Contactez Appart9 | Service Client Immobilier en Île-de-France" />
                <meta property="og:description" content="Besoin d'aide pour votre projet immobilier en Île-de-France ? Contactez l'équipe Appart9. Nous sommes là pour répondre à toutes vos questions." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.appart9.com/contact" />
                <meta property="og:image" content="%PUBLIC_URL%/assets/img/APPARTNEUFLOGO-18.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Contactez Appart9 | Service Client Immobilier en Île-de-France" />
                <meta name="twitter:description" content="Besoin d'aide pour votre projet immobilier en Île-de-France ? Contactez l'équipe Appart9. Nous sommes là pour répondre à toutes vos questions." />
                <meta name="twitter:image" content="%PUBLIC_URL%/assets/img/APPARTNEUFLOGO-18.png" />
            </Helmet>
            <Navbar />
            <PageHeader headertitle="Contactez-nous" subheader="Contact" />
            <ContactInfo />
            <ContactForm />
            <Map />
            <CallToActionV1 />
            <Footer />
        </div>
    );
}

export default ContactV1;