import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';

const ConfirmationPage = () => {
    const location = useLocation();
    const { message } = location.state || { message: "Votre action a été réalisée avec succès." };

    const styles = {
        page: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            backgroundColor: '#f4f6f9',
            fontFamily: "'Poppins', sans-serif"
        },
        container: {
            textAlign: 'center',
            background: 'white',
            padding: '40px 20px',
            borderRadius: '8px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            maxWidth: '400px',
            width: '100%',
        },
        logo: {
            width: '100px', // Taille de l'icône
            marginBottom: '20px',
        },
        icon: {
            fontSize: '72px',
            color: '#28a745',
            marginBottom: '20px',
        },
        title: {
            fontSize: '24px', // Un titre légèrement plus petit sous l'icône
            color: '#304798',
            marginBottom: '10px',
        },
        subtitle: {
            fontSize: '32px',
            color: '#333',
            marginBottom: '10px',
        },
        message: {
            fontSize: '18px',
            color: '#666',
            marginBottom: '30px',
        },
        button: {
            display: 'inline-block',
            padding: '12px 25px',
            backgroundColor: '#304798',
            color: 'white',
            textDecoration: 'none',
            transition: 'background-color 0.3s, transform 0.3s, box-shadow 0.3s',
            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
        },
        buttonHover: {
            backgroundColor: '#1c318c',
            transform: 'translateY(-2px)',
            boxShadow: '0 6px 20px rgba(0, 0, 0, 0.3)',
        },
    };

    return (
        <div style={styles.page}>
            <div style={styles.container}>
                <img src={`${process.env.PUBLIC_URL}/assets/img/LOGO.png`} alt="Logo de l'entreprise" style={styles.logo} />
                <h2 style={styles.title}></h2> {/* Titre professionnel sous le logo */}
                <FaCheckCircle style={styles.icon} />
                <h1 style={styles.subtitle}>Merci !</h1>
                <p style={styles.message}>{message}</p>
                <Link
                    to="/"
                    style={styles.button}
                    onMouseOver={(e) => {
                        e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor;
                        e.currentTarget.style.transform = styles.buttonHover.transform;
                        e.currentTarget.style.boxShadow = styles.buttonHover.boxShadow;
                    }}
                    onMouseOut={(e) => {
                        e.currentTarget.style.backgroundColor = styles.button.backgroundColor;
                        e.currentTarget.style.transform = 'translateY(0)';
                        e.currentTarget.style.boxShadow = styles.button.boxShadow;
                    }}
                >
                    Retour à la page d'accueil
                </Link>
            </div>
        </div>
    );
};

export default ConfirmationPage;
