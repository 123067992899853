import React, { useState, useCallback, useEffect } from 'react';
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

function MapSelect({ longitude, latitude, onPositionChange }) {
    const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

    const [viewState, setViewState] = useState({
        longitude: parseFloat(longitude) || 2.3490282398937445,
        latitude: parseFloat(latitude) || 48.85923805925715,
        zoom: 10
    });

    const [markerPosition, setMarkerPosition] = useState({
        longitude: parseFloat(longitude) || 2.3490282398937445,
        latitude: parseFloat(latitude) || 48.85923805925715
    });

    useEffect(() => {
        if (longitude && latitude) {
            const long = parseFloat(longitude);
            const lat = parseFloat(latitude);
            setMarkerPosition({ longitude: long, latitude: lat });
            setViewState(prev => ({ ...prev, longitude: long, latitude: lat }));
        }
    }, [longitude, latitude]);

    const handleMapClick = useCallback((event) => {
        const { lngLat } = event;
        setMarkerPosition({ longitude: lngLat.lng, latitude: lngLat.lat });
        onPositionChange(lngLat.lng, lngLat.lat);
    }, [onPositionChange]);

    return (
        <Map
            {...viewState}
            onMove={evt => setViewState(evt.viewState)}
            style={{ width: '100%', height: 400 }}
            mapStyle="mapbox://styles/mapbox/streets-v11"
            mapboxAccessToken={MAPBOX_TOKEN}
            onClick={handleMapClick}
        >
            <Marker
                longitude={markerPosition.longitude}
                latitude={markerPosition.latitude}
            />
        </Map>
    );
}

export default MapSelect;