import React from 'react';
import Map from './maps';

const MapDialog = ({ show, onClose, properties }) => {
    // console.log("MapDialog received:", { show, properties });
    if (!show || !properties || properties.length === 0) {
        return null;
    }

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            backdropFilter: 'blur(5px)',
        }}>
            <div style={{
                backgroundColor: 'white',
                borderRadius: '12px',
                padding: '20px',
                width: '80%',
                height: '85%',
                position: 'relative',
                boxShadow: '0 4px 16px rgba(0, 0, 0, 0.3)',
                paddingTop: '20px',
            }}>
                <button onClick={onClose} style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    fontSize: '18px',
                    cursor: 'pointer',
                    color: '#333',
                    fontWeight: 'bold',
                    // margin: '10px',
                }}>Fermer</button>
                <div style={{ height: '90%', width: '100%', marginTop: '20px' }}>
                    <Map properties={properties} />
                </div>
            </div>
        </div>
    );
};

export default MapDialog;