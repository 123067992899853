import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actGetAppartement } from '../../slices/appartement/AppartSlice';

const ProductSliderV1 = () => {
    const { id: productId } = useParams();
    const dispatch = useDispatch();
    const { record: product, loading, error } = useSelector(state => state.appartement);

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        dispatch(actGetAppartement(productId));
    }, [dispatch, productId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!product) {
        return <div>Produit non trouvé</div>;
    }

    const gallery = product.gallery || [];

    const handleNext = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === gallery.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handlePrevious = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? gallery.length - 1 : prevIndex - 1
        );
    };

    return (
        <div style={styles.container}>
            <div style={styles.gallerySide}>
                <img
                    src={gallery[(currentImageIndex - 1 + gallery.length) % gallery.length]}
                    alt="Previous"
                    style={styles.galleryImage}
                    onClick={handlePrevious}
                    className="hover-zoom"
                />
            </div>
            <div style={styles.mainImageWrapper}>
                <button onClick={handlePrevious} style={styles.navButtonLeft}>❮</button>
                <img
                    src={gallery[currentImageIndex]}
                    alt="Main"
                    style={styles.mainImage}
                    className="hover-zoom"
                />
                <button onClick={handleNext} style={styles.navButtonRight}>❯</button>
            </div>
            <div style={styles.gallerySide}>
                <img
                    src={gallery[(currentImageIndex + 1) % gallery.length]}
                    alt="Next"
                    style={styles.galleryImage}
                    onClick={handleNext}
                    className="hover-zoom"
                />
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        width: '100%',
        height: '600px',
        margin: 'auto',
    },
    gallerySide: {
        width: '15%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    galleryImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        cursor: 'pointer',
        transition: 'transform 0.3s ease',
    },
    mainImageWrapper: {
        width: '70%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    mainImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        transition: 'transform 0.3s ease',
    },
    navButtonLeft: {
        position: 'absolute',
        left: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        border: 'none',
        padding: '10px',
        cursor: 'pointer',
        zIndex: 2,
        borderRadius: '50%',
    },
    navButtonRight: {
        position: 'absolute',
        right: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        border: 'none',
        padding: '10px',
        cursor: 'pointer',
        zIndex: 2,
        borderRadius: '50%',
    },
};

// Additional CSS for hover effect
const additionalStyles = `
.hover-zoom:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
}
`;

document.head.insertAdjacentHTML(
    'beforeend',
    `<style>${additionalStyles}</style>`
);

export default ProductSliderV1;
