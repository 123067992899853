import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const AProposV2 = () => {
    let publicUrl = process.env.PUBLIC_URL + '/';

    const sectionRef = useRef(null);
    const contentRef = useRef(null);
    const imagesRef = useRef(null);
    const imagePrincipaleRef = useRef(null);

    useEffect(() => {
        // ... (keeping the existing useEffect code unchanged)
    }, []);

    return (
        <div ref={sectionRef} className="ltn__about-us-area pb-40">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 align-self-center">
                        <div ref={contentRef} className="about-us-info-wrap">
                            <div className="section-title-area ltn__section-title-2--- mb-30">
                                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">À propos de nous</h6>
                                <h1 className="section-title">Nous vous proposons de trouver votre bien</h1>
                                <p>Vous cherchez un studio cosy, un appartement avec deux chambres ou un grand espace familial ?</p>
                            </div>
                            <p>Appartement 9 est là pour vous ! Découvrez notre sélection d'appartements situés dans les meilleurs quartiers de l'Île-de-France, proches des principales lignes de transports. Vous êtes à deux clics de votre futur chez-vous</p>
                            <div className="d-flex align-items-center mt-4">
                                {/* <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-3 col-md-6"> */}
                                <div className="btn-wrapper text-center mt-0 go-top">
                                    <button type="submit" className="btn theme-btn-1 btn-effect-1 text-uppercase">Decouvrir</button>
                                </div>
                                {/* </div> */}
                            </div>
                            <ul ref={imagesRef} className="ltn__list-item-2 ltn__list-item-2-before--- ltn__list-item-2-img mb-50">
                                <li>
                                    <a href={publicUrl + "assets/img/banner/bannerap1.jpg"} data-rel="lightcase:myCollection">
                                        <img src={publicUrl + "assets/img/banner/bannerap1.jpg"} alt="Image" />
                                    </a>
                                </li>
                                <li>
                                    <a href={publicUrl + "assets/img/banner/bannerap2.jpg"} data-rel="lightcase:myCollection">
                                        <img src={publicUrl + "assets/img/banner/bannerap2.jpg"} alt="Image" />
                                    </a>
                                </li>
                                <li>
                                    <a href={publicUrl + "assets/img/banner/bannerap5.jpg"} data-rel="lightcase:myCollection">
                                        <img src={publicUrl + "assets/img/banner/bannerap5.jpg"} alt="Image" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div ref={imagePrincipaleRef} className="about-us-img-wrap about-img-right">
                            <img src={publicUrl + "assets/img/banner/bannerap.jpg"} alt="Image À propos de nous" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AProposV2;