import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submitActualites, getActualiteById, actUpdateActualite } from '../../slices/actualite/ActualiteSlice';
import { useAlert } from 'react-alert';
import { useParams } from 'react-router-dom';

function UpdateAct() {
    const dispatch = useDispatch();
    const alert = useAlert();
    const { id } = useParams();
    const actualite = useSelector(state => state.actualites.items[0]); // Assuming the first item is the one we want
    const [formData, setFormData] = useState({
        id: '',
        title: '',
        description: '',
        title1: '',
        description1: '',
        title2: '',
        description2: '',
        title3: '',
        description3: '',
        title4: '',
        description4: '',
        author: '',
        date: '',
    });

    useEffect(() => {
        if (id) {
            console.log("Fetching actualite with id:", id);
            dispatch(getActualiteById(id));
        }
    }, [id, dispatch]);

    useEffect(() => {
        if (actualite) {
            console.log("Setting form data with actualite:", actualite);
            setFormData({
                id: actualite._id || '',
                title: actualite.title || '',
                description: actualite.description || '',
                title1: actualite.title1 || '',
                description1: actualite.description1 || '',
                title2: actualite.title2 || '',
                description2: actualite.description2 || '',
                title3: actualite.title3 || '',
                description3: actualite.description3 || '',
                title4: actualite.title4 || '',
                description4: actualite.description4 || '',
                author: actualite.author || '',
                date: actualite.date || '',
            });
        }
    }, [actualite]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("Setting form data:", name, value);

        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const submitData = new FormData();

        // Append form data
        Object.keys(formData).forEach(key => {
            submitData.append(key, formData[key]);
        });

        // Append file if it exists
        const fileInput = event.target.elements.image;
        if (fileInput.files[0]) {
            submitData.append('image', fileInput.files[0]);
        }

        try {
            const response = await dispatch(actUpdateActualite({ id, formData }));
            if (submitActualites.fulfilled) {
                alert.success('Actualites updated successfully!', { type: 'success' });
            } else {
                // alert.error('Failed to update actualites', { type: 'error' });
                throw new Error('Failed to update actualites');
            }
        } catch (error) {
            alert.error(`Error: ${error.message || 'Unknown error'}`);
        }
    };

    return (
        <div className="ltn__appointment-area pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ltn__appointment-inner">
                            <form onSubmit={handleSubmit}>
                                <h2>1. Description</h2>
                                <p><small>These fields are mandatory: Title, Media</small></p>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h6>1. Title Principale</h6>
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input
                                                type="text"
                                                name="title"
                                                placeholder="*Title (mandatory)"
                                                value={formData.title}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <h6>2. Description Principale</h6>
                                        <div className="input-item input-item-textarea ltn__custom-icon">
                                            <textarea
                                                name="description"
                                                placeholder="* Description"
                                                value={formData.description}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <h6>3. Title N°1</h6>
                                        <div className="input-item input-item-textarea">
                                            <input
                                                style={{ height: "50px" }}
                                                type="text"
                                                name="title1"
                                                placeholder="Title N°1"
                                                value={formData.title1}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <h6>4. Description N°1</h6>
                                        <div className="input-item input-item-textarea ltn__custom-icon">
                                            <textarea
                                                name="description1"
                                                placeholder="Description N°1"
                                                value={formData.description1}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <h6>5. Title N°2</h6>
                                        <div className="input-item input-item-textarea">
                                            <input
                                                style={{ height: "50px" }}
                                                type="text"
                                                name="title2"
                                                placeholder="Title N°2"
                                                value={formData.title2}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <h6>6. Description N°2</h6>
                                        <div className="input-item input-item-textarea ltn__custom-icon">
                                            <textarea
                                                name="description2"
                                                placeholder="Description N°2"
                                                value={formData.description2}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <h6>7. Title N°3</h6>
                                        <div className="input-item input-item-textarea">
                                            <input
                                                style={{ height: "50px" }}
                                                type="text"
                                                name="title3"
                                                placeholder="Title N°3"
                                                value={formData.title3}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <h6>8. Description N°3</h6>
                                        <div className="input-item input-item-textarea ltn__custom-icon">
                                            <textarea
                                                name="description3"
                                                placeholder="Description N°3"
                                                value={formData.description3}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <h6>9. Title N°4</h6>
                                        <div className="input-item input-item-textarea">
                                            <input
                                                style={{ height: "50px" }}
                                                type="text"
                                                name="title4"
                                                placeholder="Title N°4"
                                                value={formData.title4}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <h6>10. Description N°4</h6>
                                        <div className="input-item input-item-textarea ltn__custom-icon">
                                            <textarea
                                                name="description4"
                                                placeholder="Description N°4"
                                                value={formData.description4}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <h6>11. Author</h6>
                                        <div className="input-item input-item-textarea">
                                            <input
                                                style={{ height: "50px" }}
                                                type="text"
                                                name="author"
                                                placeholder="Author"
                                                value={formData.author}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <h6>12. Date</h6>
                                        <div className="input-item input-item-name" style={{ marginTop: "10px", marginBottom: '32px' }}>
                                            <input
                                                type="date"
                                                name="date"
                                                value={formData.date}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <h2>2. Media</h2>
                                <h6>Listing Media</h6>
                                <input type="file" id="myFile" name="image" className="btn theme-btn-3 mb-10" /><br />
                                <p>
                                    <small>* At least 1 image is required for a valid submission. Minimum size is 500/500px.</small><br />
                                    <small>* Images might take longer to be processed.</small>
                                </p>
                                <div className="btn-wrapper text-center mt-30">
                                    <button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">Update Actualites</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpdateAct;