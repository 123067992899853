import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actGetAppartements } from '../../slices/appartement/AppartSlice';

const ProductListingV1 = () => {
    const dispatch = useDispatch();
    const { records: appartements, loading, error } = useSelector(state => state.appartement);
    const [latestAppartements, setLatestAppartements] = useState([]);

    useEffect(() => {
        dispatch(actGetAppartements());
    }, [dispatch]);

    useEffect(() => {
        if (appartements && appartements.length > 0) {
            // Trier les appartements par date de création et obtenir les 6 derniers
            const sortedAppartements = [...appartements].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setLatestAppartements(sortedAppartements.slice(0, 6));
        }
    }, [appartements]);

    let publicUrl = process.env.PUBLIC_URL + '/';

    return (
        <div>
            <div className="ltn__product-slider-area ltn__product-gutter pt-115 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title-area ltn__section-title-2--- text-center">
                                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Propriété</h6>
                                <h1 className="section-title">Les Nouveaux Derniers.</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row ltn__product-slider-item-three-active--- slick-arrow-1">
                        {loading ? (
                            <div>Chargement...</div>
                        ) : error ? (
                            <div>Une erreur est survenue lors de la récupération des données. Veuillez réessayer plus tard.</div>
                        ) : appartements.length === 0 ? (
                            <div>Aucun appartement disponible pour le moment.</div>
                        ) : (
                            latestAppartements.map((product) => (
                                <div className="col-xl-4 col-sm-6 col-12" key={product._id}>
                                    <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                                        <div className="product-img go-top">
                                            <Link to={`/product-details/${product._id}`}>
                                                <img src={product.image} alt={product.title} />
                                            </Link>

                                        </div>
                                        <div className="product-info">
                                            <div className="product-badge">
                                                <ul>
                                                    <li className="sale-badg">{product.type === "Vente" ? "À Vendre" : "À Louer"}</li>
                                                </ul>
                                            </div>
                                            <h2 className="product-title go-top">
                                                <Link to={`/product-details/${product._id}`}>{product.title}</Link>
                                            </h2>
                                            <div className="product-img-location">
                                                <ul>
                                                    <li>
                                                        <Link to="/contact">
                                                            <i className="flaticon-pin" /> {product.quartier}
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                            <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                                                <li><span>{product.bedrooms} </span> Chambres</li>
                                                <li><span>{product.bathrooms} </span> Salles de Bain</li>
                                                <li><span>{product.surface} </span> m²</li>
                                            </ul>
                                            <div className="product-hover-action">
                                                <ul>
                                                    <li>
                                                        <a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
                                                            <i className="flaticon-expand" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
                                                            <i className="flaticon-heart-1" />
                                                        </a>
                                                    </li>
                                                    <li className="go-top">
                                                        <Link to={`/product-details/${product._id}`} title="Product Details">
                                                            <i className="flaticon-add" />
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="product-info-bottom">
                                            <div className="product-price">
                                                <span>
                                                    {product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} €
                                                    {/* Affiche le priceLabel seulement s'il ne contient pas le symbole € */}
                                                    {product.priceLabel && !product.priceLabel.includes('€') && (
                                                        <label>{product.priceLabel}</label>
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductListingV1;