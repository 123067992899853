import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import BlogGrid from './blog-components/blog-grid';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const BlogGridPage = () => {
    return (
        <div>
            <Helmet>
                <title>Actualités Immobilières en Île-de-France | Appart9</title>
                <meta name="description" content="Restez informé des dernières actualités et tendances du marché immobilier en Île-de-France. Découvrez nos analyses et conseils pour votre projet immobilier." />
                <meta name="keywords" content="Actualités immobilières, Nouvelles du marché immobilier, Tendances immobilières, Informations Île-de-France, Dernières nouvelles" />
                <meta property="og:title" content="Actualités Immobilières en Île-de-France | Appart9" />
                <meta property="og:description" content="Suivez les dernières tendances et nouvelles du marché immobilier en Île-de-France. Informations et analyses pour guider vos décisions immobilières." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.appart9.com/actualites" />
                <meta property="og:image" content="%PUBLIC_URL%/assets/img/APPARTNEUFLOGO-18.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Actualités Immobilières en Île-de-France | Appart9" />
                <meta name="twitter:description" content="Suivez les dernières tendances et nouvelles du marché immobilier en Île-de-France. Informations et analyses pour guider vos décisions immobilières." />
                <meta name="twitter:image" content="%PUBLIC_URL%/assets/img/APPARTNEUFLOGO-18.png" />
            </Helmet>
            <Navbar />
            <PageHeader headertitle="Actualités Immobilières" />
            <BlogGrid />
            <CallToActionV1 />
            <Footer />
        </div>
    );
}

export default BlogGridPage;