import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getRecentActualites } from '../../slices/actualite/ActualiteActions';
import Slider from 'react-slick';
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "40px",
                height: "40px",
                background: "white",
                border: "2px solid white", 
                color: "#304798",
                borderRadius: "50%", 
                position: "absolute",
                right: "10px",
                zIndex: "2",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                transition: "all 0.3s ease",
            }}
            onClick={onClick}
            onMouseEnter={(e) => {
                e.currentTarget.style.border = "2px solid #304798";
                e.currentTarget.style.color = "white";
                e.currentTarget.style.background = "#304798";  // Change de couleur au hover
            }}
            onMouseLeave={(e) => {
                e.currentTarget.style.border = "2px solid white";
                e.currentTarget.style.color = "#304798";
                e.currentTarget.style.background = "white";  // Revenir à la couleur par défaut
            }}
        >
            <i className="fa fa-chevron-right" />
        </div>
    );
}
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "40px",
                height: "40px",
                background: "white",  // Cercle blanc
                border: "2px solid white",  // Bordure bleue
                color: "#304798",  // Flèche bleue
                borderRadius: "50%",  // Cercle
                position: "absolute",
                left: "10px",
                zIndex: "2",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
            }}
            onClick={onClick}
            onMouseEnter={(e) => {
                e.currentTarget.style.border = "2px solid #304798";
                e.currentTarget.style.color = "white";
                e.currentTarget.style.background = "#304798";  // Change de couleur au hover
            }}
            onMouseLeave={(e) => {
                e.currentTarget.style.border = "2px solid white";
                e.currentTarget.style.color = "#304798";
                e.currentTarget.style.background = "white";  // Revenir à la couleur par défaut
            }}
        >
            <i className="fa fa-chevron-left" />
        </div>
    );
}
const BlogSlider = () => {
    const dispatch = useDispatch();
    const { recentItems: actualites, loading, error } = useSelector(state => state.actualites);
    const [latestActualites, setLatestActualites] = useState([]);

    useEffect(() => {
        dispatch(getRecentActualites());
    }, [dispatch]);

    useEffect(() => {
        if (actualites && actualites.length > 0) {
            const sortedActualites = [...actualites].sort((a, b) => new Date(b.date) - new Date(a.date));
            setLatestActualites(sortedActualites.slice(0, 5));
        }
    }, [actualites]);
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
    };
    return (
        <div className="ltn__product-slider-area ltn__product-gutter pt-115 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area ltn__section-title-2--- text-center">
                            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">News & Blogs</h6>
                            <h1 className="section-title">Actualités</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        {loading ? (
                            <div>Chargement...</div>
                        ) : error ? (
                            <div>Une erreur est survenue lors de la récupération des données. Veuillez réessayer plus tard.</div>
                        ) : actualites.length === 0 ? (
                            <div>Aucune actualité disponible pour le moment.</div>
                        ) : (
                            <Slider {...settings}>
                                {latestActualites.map((news) => (
                                    <div className="col-xl-4 col-sm-6 col-12" key={news._id}>
                                        <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                                            <div className="product-img go-top">
                                                <Link to={`/blog-details/${news._id}`}>
                                                    <img src={news.image} alt={news.title} />
                                                </Link>
                                            </div>
                                            <div className="product-info">
                                                {/* <div className="product-badge">
                                                    <ul>
                                                        <li className="sale-badg">
                                                            <i className="far fa-user" /> by: {news.author}
                                                        </li>
                                                    </ul>
                                                </div> */}
                                                <h2 className="product-title go-top">
                                                    <Link to={`/blog-details/${news._id}`}>{news.title}</Link>
                                                </h2>
                                                <div className="product-img-location">
                                                    <ul>
                                                        <li>
                                                            <i className="far fa-calendar-alt" /> {new Date(news.date).toLocaleDateString('fr-FR', {
                                                                day: 'numeric', month: 'long', year: 'numeric'
                                                            })}
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="product-hover-action">
                                                    <ul>
                                                        <li>
                                                            <Link to={`/blog-details/${news._id}`} title="Read More">
                                                                <i className="flaticon-add" />
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogSlider;
